<template>
  <div class="page-container-renewal">
    <div v-if="alertMessage" class="col-12">
      <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
    </div>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div class="page-content-renewal">
      <div class="page-header-billet">
        <div class="page-content-billet">
        <div class="d-flex align-items-center">

          <h1 class="text-white"><i class="mdi mdi-barcode-scan"></i> Boletos</h1>

          <div class="d-flex gap-2 ml-auto align-items-center">
            <div class="button-group button-group-renewal">
              <button class="btn btn-outline-white mt-1" @click="openFilterModal()"
                style="border-radius: 5px 0px 0px 5px;">
                <i class="fas fa-filter"></i> <span class="d-md-inline-block ml-2">Filtros</span>
              </button>
              <button class="btn btn-outline-white mt-1" @click="clearFilters()"
                style="border-radius: 0px 5px 5px 0px;">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="card-body p-0">
          <DatatableBillets ref="datatableBillets" :filters="filters" />
        </div>
      </div>
    </div>
    </div>

    <b-modal id="filter-modal" ref="filterModal" size="md" hide-footer centered>
      <template #modal-title>Filtros</template>

      <div class="form-group">
        <label for="filterPolicyHolder">Tomador</label>
        <b-form-input v-model="filters.policy_holder"></b-form-input>
      </div>

      <div class="form-group">
        <label for="filterInsured">Segurado</label>
        <b-form-input v-model="filters.insured"></b-form-input>
      </div>

      <div class="form-group">
        <label for="filterPolicyNumber">Status</label>
        <b-form-select v-model="filters.status_id" :options="options()" class="mb-3">
        </b-form-select>
      </div>

      <div class="d-block mt-3">
        <div class="d-flex justify-content-between">
          <b-button variant="outline-secondary" @click="$bvModal.hide('filter-modal')">
            Cancelar
          </b-button>
          <b-button variant="primary" @click="applyFilters()">
            Aplicar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatatableBillets from './DatatableBillets.vue';

export default {
  name: "ListBillets",
  components: {
    DatatableBillets,
  },
  data() {
    return {
      itemsPolicies: [],
      itemsPolicyRenovation: [],
      loading: false,
      filters: {
        policy_holder: '',
        insured: '',
        status_id: null,
      },
    };
  },
  created() {
    //
  },
  methods: {
    applyFilters() {
      this.$bvModal.hide('filter-modal');
      this.$refs.datatableBillets.fetchBillets(this.filters);
    },
    openFilterModal() {
      this.$bvModal.show('filter-modal');
    },
    clearFilters() {
      this.filters.policy_holder = '';
      this.filters.insured = '';
      this.filters.status_id = '';
    },
    setLoading(status) {
      this.loading = status;
    },
    options() {
      let options = [];
      let status = Object.entries({
        'Pago': 19,
        'Vencido': 20,
        'Em aberto': 21
      });
      status.forEach((element) => {
        options.push({ value: element[1], text: element[0] });
      });
      options.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
      options.splice(0, 0, {
        value: null,
        text: "Sem Filtro",
        notEnabled: true,
        disabled: true,
      });
      return options;
    },
  }
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="kanban-body">
      <div class="p-0">
        <div class="kanban-board">
          <div class="col col-kanban" v-for="(column, index) in columnKanbanPolicies()" :key="index">
            <div class="card shadow mb-3 border-card">
              <div v-bind:class="cardColors[index]" class="kanban-list" :data-list-id="index">
                <div class="kanban-list-header">
                  <div class="kanban-list-title">
                    {{ getNameColumn(column) }}
                  </div>
                  <div class="float-right kanban-list-total-cards">
                    <i class="fa fa-copy"></i>
                    <span class="ml-1">{{ cardCount(column) }}</span>
                  </div>
                </div>
              </div>

              <div class="card-body fade-in kanban-list-cards"
                :class="{ 'scroll-disabled': loadingColumns[column] }"
                v-if="itemsPolicies[column]"
                @scroll="onScroll(column)"
                :ref="`kanbanListCards${column}`">
                <draggable
                  :list="itemsPolicies[column]"
                  :group="{ name: 'kanban', pull: true, put: true }"
                  :move="onMoveCard"
                  :data-list-id="column"
                  @start="onCardGet($event, 1)"
                  @end="onCardDrop($event)"
                  ghost-class="dragging-shadow"
                  class="data-list-limit">
                  <div v-for="(policy, index) in itemsPolicies[column]" :key="index">
                    <div class="kanban-card">
                      <div class="kanban-card-content">
                        <div class="d-flex align-items-start" v-if="policy">
                          <div class="d-block">
                            <strong class="pointer smart-clip"
                              @click="$can('policy-show') ? redirect('details-policy', { policyId: policy.id }) : null">
                              {{ truncateText(policy.policy_holder_name) }}
                            </strong><br />
                            <div class="row">
                              <div class="col-sm-12 smart-clip">
                                <span class="pointer"
                                  @click="$can('policy-show') ? redirect('details-policy', { policyId: policy.id }) : null">
                                  <i class="mdi mdi-folder-open fa fa-fw mr-1"></i>{{ 'Apólice nº ' + (policy.number) }}
                                </span><br />
                              </div>
                              <div class="col-12 smart-clip">
                                <i class="far fa-user mr-2"></i><span>{{ truncateText(policy.insured_name ?? '--') }}</span>
                              </div>
                              <div :class="getClassTextColor(policy.deadline)" class="pill-label-kanban">
                                <span class="label"><i class="fas fa-calendar-alt mr-1"></i></span>
                                <small class="value" data-toggle="tooltip"
                                  :title="(policy.deadline > 0 ? 'Vence em ' : 'Venceu há ') + Math.abs(policy.deadline) + ' dia(s)'"
                                  v-b-tooltip.hover>
                                  {{ policy.validity_expiration }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div v-bind:class="cardColors[getRealNameColumn(column)]" class="status-bar"
                            :style="{ backgroundColor: cardColorsTags[getRealNameColumn(column)] }"></div>
                          <div class="mt-4">
                            <span v-show="$can.any(['policy-edit', 'policy-update'])" @click="showModalCreate(policy)"
                              class="mt-4 position-sync-icon" v-b-tooltip.hover title="Renovar apólice">
                              <button type="button"
                                class="button btn btn-sm btn-link text-secondary  hover-button">
                                <i class="fa fa-sync"></i>
                              </button>
                            </span>
                            <span v-if="isMaster()" @click="showModalAutomaticRenewal(policy)" class="mt-4 position-sync-icon"
                              v-b-tooltip.hover title="Renovação automática">
                              <button type="button"
                                class="button btn btn-sm btn-link text-secondary ml-auto hover-button">
                                <i class="fa fa-clock"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="loadingColumns[column]"  class="loading-overlay">
                  <b-spinner small label="Carregando..."></b-spinner>
                </div>
              </div>

              <div class="card-body fade-in kanban-list-cards" v-if="itemsPolicyRenovation[column]" @scroll="onScroll(column)"
                :ref="`kanbanListCards${column}`" :class="{ 'scroll-disabled': loadingColumns[column] }">
                <draggable
                  :list="itemsPolicyRenovation[column]"
                  :group="{ name: 'kanban', pull: true, put: true }"
                  :data-list-id="column"
                  :move="onMoveCard"
                  class="data-list-limit"
                  @start="onCardGet($event, 2)"
                  @end="onCardDrop($event)"
                  ghost-class="dragging-shadow"
                >
                  <div v-for="(policyRenovation, index) in itemsPolicyRenovation[column]" :key="index">
                    <div class="kanban-card">
                      <div class="kanban-card-content">
                        <div class="d-flex align-items-start" v-if="policyRenovation">
                          <div class="d-block">
                            <p class="pointer m-0 smart-clip"
                              @click="$can('policy-show') ? redirect('details-policy', { policyId: policyRenovation.policy_origin.id }) : null">
                              <strong>{{ truncateText(policyRenovation.policy_origin && policyRenovation.policy_origin.policy_holder_name ? policyRenovation.policy_origin.policy_holder_name : '--') }}</strong>
                            </p>
                            <div class="row-kanban">
                              <div class="col-sm-9">
                                <span class="pointer"
                                  @click="$can('policy-show') ? redirect('details-policy', { policyId: policyRenovation.policy_origin.id }) : null">
                                  <i class="mdi mdi-folder-open fa fa-fw"></i>Apólice nº {{ policyRenovation.policy_origin && policyRenovation.policy_origin.number ? policyRenovation.policy_origin.number : '--' }}
                                </span><br />
                                <span
                                  @click="$can('policy-show') ? redirect('details-quotation', { quotationId: policyRenovation.quotation_id }) : null">
                                  <i class="mdi mdi-buffer fa fa-fw mr-1"></i>Cotação nº {{( policyRenovation.quotation  && policyRenovation.quotation.number ? policyRenovation.quotation.number : policyRenovation.quotation_number) ?? '--'  }}
                                </span><br />
                              </div>
                              <div class="col-12">
                                <span><i class="far fa-user mr-2"></i>{{
                                  truncateText(policyRenovation.policy_origin && policyRenovation.policy_origin.insured_name ? policyRenovation.policy_origin.insured_name : policyRenovation.insured_name ) ?? '--' }}</span>
                              </div>
                            </div>
                          </div>
                          <div v-bind:class="cardColors[getRealNameColumn(column)]" class="status-bar"
                            :style="{ backgroundColor: cardColorsTags[getRealNameColumn(column)] }"></div>
                          <div class="kanban-card-actions">
                            <button type="button" class="button btn btn-sm btn-link text-secondary ml-auto"
                              v-show="$can.any(['policy-edit', 'policy-update'])"
                              @click="showModalStatus(policyRenovation, column, true)" v-b-tooltip.hover
                              title="Mais informações">
                              <i class="fa fa-pen"></i>
                            </button>
                            <button type="button" class="button btn btn-sm btn-link text-secondary ml-auto"
                              v-if="policyRenovation.policy_renovation_files"
                              :disabled="loading_download || !policyRenovation.policy_renovation_files || policyRenovation.policy_renovation_files.length === 0"
                              v-b-tooltip.hover title="Fazer download de todos os arquivos"
                              @click="downloadAllFile(policyRenovation)">
                              <i class="fas fa-paperclip"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="loadingColumns[column]" class="loading-overlay">
                  <b-spinner small label="Carregando..."></b-spinner>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <b-modal id="modal-renovation-status" ref="modalStatus" size="lg" @hide="closeModalAndMoveBack">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Renovação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da renovação</p>
          </div>
        </div>
      </template>
      <ModalStatusRenewal
        :policy-renew-selected="policyRenewSelected"
        :editing="editing"
        :url-upload="urlUpload"
        :files="files"
        @close-modal-status="closeModalStatus"
        @disable-status-button="disableStatusButton"
        ref="modalStatusRenewal"/>
      <template #modal-footer>
        <button 
          type="button" 
          class="btn btn-primary"
          @click="editing ? changeStatusPolicyRenovation() : $refs.modalStatus.hide();"
          :disabled="disable_status_button">
          <b-spinner v-if="loading" small></b-spinner> {{ editing ? 'Alterar' : 'Fechar' }}
        </button>
      </template>
    </b-modal>

    <div class="modal">
      <b-modal id="show-modal-create" ref="modalCreate" size="md" class="mt-4" hide-footer centered @hide="closeModalAndMoveBack">
        <template #modal-title>Renovar apólice</template>
          <ManualPolicyRenewal :policy-renew-selected="policyRenewSelected"/>
      </b-modal>
    </div>

    <div class="modal">
      <b-modal id="show-modal-automatic-renewal" ref="modalAutomaticRenewal" size="lg" hide-footer @hide="closeModalAndMoveBack">
        <template #modal-title>Renovar apólice</template>
          <div class="row form">
            <div class="col-12">
              <AutomaticPolicyRenewal :policy-id="policyIdSelected"/>
            </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import policyRenovationService from "@/services/policy-renovation";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatter from "@/mixins/numericFormatter";
import authenticationService from "@/services/authentication";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import AutomaticPolicyRenewal from '@/components/Policy/Renewal/AutomaticPolicyRenewal.vue';
import ManualPolicyRenewal from '@/components/Policy/Renewal/ManualPolicyRenewal.vue';
import ModalStatusRenewal from '@/components/Policy/Renewal/ModalStatusRenewal.vue';
import fileDownload from "js-file-download";

export default {
  name: "list-kanban-policy-renewal",
  components: {
    draggable,
    AutomaticPolicyRenewal,
    ManualPolicyRenewal,
    ModalStatusRenewal
  },
  mixins: [dateFormatter, commomsHelper, numericFormatter],
  props: {
    itemsPolicies: {},
    itemsPolicyRenovation: {},
    pages: [],
  },
  data: () => ({
    loading: false,
    loading_download: false,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    statusQuotationTypes: statusQuotationTypes,
    cardColors: {
      UPCOMING: "kanban-list-status-orange",
      CONTACT: "kanban-list-status-orange-66",
      AWAITING: "kanban-list-status-orange-33",
      AWAITING_APPROVAL: "kanban-list-status-yellow",
      FINISHED: "kanban-list-status-green",
      CANCELED: "kanban-list-status-red",
    },
    policyRenewSelected: {
      policy_origin: {
        insured_name: null,
        policy_holder_document: null,
        policy_holder_name: null,
        number: null,
      },
    },
    loadingColumns: {
      1: false,
      2: false,
      15: false,
      16: false,
      18: false,
      9: false,
    },
    editing: false,
    files: [],
    urlUpload: '',
    cardColorsTags: {
      UPCOMING: "#ea712d",
      CONTACT: "#f18830",
      AWAITING: "#f89f32",
      AWAITING_APPROVAL: "#ffb635",
      AWAITING_DOCUMENTATION: "#ffb635",
      FINISHED: "#20c997",
      CANCELED: "crimson",
    },
    policyIdSelected: null,
    movedPolicy: null,
    fromColumn: null,
    toColumn: null,
    originalIndex: null,
    disable_status_button: true,
  }),
  computed: {
    cardCount() {
      return (column) => {
        var policyItems = this.itemsPolicies[column];
        var renovationItems = this.itemsPolicyRenovation[column];

        if (policyItems) {
          return policyItems.length;
        }
        if (renovationItems) {
          return renovationItems.length;
        }
        return 0;
      };
    }
  },
  created() {
    this.$emit('update-kanban');
  },
  methods: {
    onScroll(column) {
      const container = this.$refs[`kanbanListCards${column}`][0];
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        this.addMoreResults(column);
      }
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    columnKanbanPolicies() {
      return {
        UPCOMING: 1,
        CONTACT: 2,
        AWAITING: 15,
        AWAITING_APPROVAL: 18,
        FINISHED: 16,
        CANCELED: 9,
      };
    },
    addMoreResults(column) {
      if (this.pages[column].current_page < this.pages[column].last_page && !this.loadingColumns[column]) {
        this.loadingColumns[column] = true;
        var nextPage = this.pages[column].current_page + 1;
        const policyDueParams = {
          1: [120, 60],
          2: [60, -5]
        };

        if (policyDueParams[column]) {
          this.$emit('get-policy-due', ...policyDueParams[column], nextPage);
        } else if ([15, 18, 9, 16].includes(column)) {
          this.$emit('get-policies-renovations', column, nextPage);
        }
      }
    },
    showModalCreate(policy) {
        this.policyRenewSelected = policy;
        this.$refs.modalCreate.show();
    },
    showModalAutomaticRenewal(policy) {
      this.policyIdSelected = policy.id;
      this.$refs.modalAutomaticRenewal.show();
    },
    showModalStatus(policy, column, is_click) {
      if(column !== 9) {
        policy.cancellation_reason_id = null;
      }
      if(is_click) {
        policy.status_id = column;
      } else {
        policy.status_id = this.toColumn;
      }
      this.editing = column == 15 || column == 18;
      if (policy) {
        this.urlUpload = `/api/v1/policy-renovations/${policy.id}/files`;
        this.policyRenewSelected = policy;
        this.$refs.modalStatus.show();
      }
    },
    changeStatusPolicyRenovation() {
      this.loading = true;
      this.resetAlert();
      policyRenovationService
        .updateStatusPolicyRenovation(this.policyRenewSelected.id, this.policyRenewSelected)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.$refs.modalStatusRenewal.uploadFiles();
        })
        .catch(() => {
          this.$emit('show-error', "Não foi possível mudar o status da renovação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    destroyPolicyRenovation() {
      this.loading_delete = true;
      this.$refs.showModal.hide();
      policyRenovationService
        .destroyPolicyRenovation(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível remover a renovação.');
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading_delete = false;
        });
    },
    downloadAllFile(policyRenovation) {
      this.loading_download = true;
      policyRenovationService
        .getPolicyRenovationAllFiles(policyRenovation.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          fileDownload(response.data, 'download.zip');
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível realizar o download deste arquivo.');
        })
        .finally(() => {
          this.loading_download = false;
        });
    },
    getNameColumn(column) {
      switch (column) {
        case 1:
          return 'Renovação próxima';
        case 2:
          return 'Aguardando contato';
        case 15:
          return 'Aguardando documentação';
        default:
          return this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, column);
      }
    },
    getRealNameColumn(column) {
      switch (column) {
        case 1:
          return 'UPCOMING';
        case 2:
          return 'CONTACT';
        default:
          return this.getEnumKeyByEnumValue(this.statusQuotationTypes, column);
      }
    },
    getClassTextColor(days) {
      if (days < 7) {
        return 'pill-label-danger';
      } else if (days < 15) {
        return 'pill-label-warning';
      }
    },
    onCardDrop(event) {
      let toColumn = event.to.getAttribute('data-list-id');
      if (!this.canDropToColumn()) {
        return;
      }

      if ((this.fromColumn == 1 || this.fromColumn == 2) && toColumn == 15) {
        this.showModalCreate(this.movedPolicy);
      }

      if (
        (this.fromColumn === "9" && ["15", "16", "18"].includes(toColumn)) ||
        (this.fromColumn === "15" && ["9", "16", "18"].includes(toColumn)) ||
        (this.fromColumn === "16" && ["9", "15", "18"].includes(toColumn)) ||
        (this.fromColumn === "18" && ["9", "15", "16"].includes(toColumn))
      ) {
        this.showModalStatus(this.movedPolicyRenovation, this.fromColumn, false);
      }
    },
     onCardGet(event, status) {
        this.fromColumn = event.from.getAttribute('data-list-id');
        this.originalIndex = event.oldIndex;

        if (status == 1) {
          this.movedPolicy = this.itemsPolicies[this.fromColumn][event.oldIndex] ?? null;
          this.movedPolicy.policy_origin = {
            id: this.movedPolicy.id,
            number: this.movedPolicy.number,
            policy_holder_document: this.movedPolicy.policy_holder_document,
            policy_holder_name: this.movedPolicy.policy_holder_name,
            validity_expiration: this.movedPolicy.validity_expiration,
          };
          this.itemsPolicies[this.fromColumn][event.oldIndex].policy_origin = this.movedPolicy.policy_origin;
        }

        if (status == 2) {
          this.movedPolicyRenovation = this.itemsPolicyRenovation[this.fromColumn][event.oldIndex] ?? null;
          this.movedPolicyRenovation.number = this.movedPolicyRenovation.policy_origin.number;
          this.movedPolicyRenovation.policy_holder_document = this.movedPolicyRenovation.policy_origin.policy_holder_document;
          this.movedPolicyRenovation.policy_holder_name = this.movedPolicyRenovation.policy_origin.policy_holder_name;
          this.movedPolicyRenovation.validity_expiration = this.movedPolicyRenovation.policy_origin.validity_expiration;

          this.itemsPolicyRenovation[this.fromColumn][event.oldIndex] = this.movedPolicyRenovation;
        }
      },
    truncateText(text, maxChar = 22) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    setloadingColum(column) {
        this.loadingColumns[column] = false;
    },
    canDropToColumn() {
      if (this.fromColumn == 1 && (this.toColumn == 15 || this.toColumn == 1)) {
        return true;
      }
      if (this.fromColumn == 2 && (this.toColumn == 15 || this.toColumn == 2)) {
        return true;
      }
      if (this.fromColumn == 15 && (this.toColumn == 15 || this.toColumn == 18 || this.toColumn == 16 || this.toColumn == 9)) {
        return true;
      }
      if (this.fromColumn == 18 && (this.toColumn == 18 || this.toColumn == 16 || this.toColumn == 9)) {
        return true;
      }
      return false;
    },
    onMoveCard(evt) {
      this.toColumn = evt.to.dataset.listId;
      if (!this.canDropToColumn()) {
        evt.from.classList.add('invalid-move');
        setTimeout(() => evt.from.classList.remove('invalid-move'), 1000);
        return false;
      }
      return true;
    },
    closeModalAndMoveBack() {
    if (this.fromColumn == 1 || this.fromColumn == 2) {
        if (this.itemsPolicies[this.fromColumn]) {
          this.itemsPolicies[this.fromColumn].splice(this.originalIndex, 0, this.movedPolicy);
        }

        if (this.itemsPolicyRenovation[this.toColumn]) {
          const toColumnIndex = this.itemsPolicyRenovation[this.toColumn].indexOf(this.movedPolicy);

          if (toColumnIndex !== -1) {
            this.itemsPolicyRenovation[this.toColumn].splice(toColumnIndex, 1);
          }
        }
      }

    if (this.fromColumn != 1 && this.fromColumn != 2) {
        if (this.itemsPolicyRenovation[this.fromColumn]) {
          this.itemsPolicyRenovation[this.fromColumn].splice(this.originalIndex, 0, this.movedPolicyRenovation);
        }

        if (this.itemsPolicyRenovation[this.toColumn]) {
          const toColumnIndex = this.itemsPolicyRenovation[this.toColumn].indexOf(this.movedPolicyRenovation);
          if (toColumnIndex !== -1) {
            this.itemsPolicyRenovation[this.toColumn].splice(toColumnIndex, 1);
          }
        }
      }

      this.movedPolicy = null;
      this.movedPolicyRenovation = null;
      this.fromColumn = null;
      this.toColumn = null;

      this.$refs.modalCreate.hide();
      this.$refs.modalStatus.hide();
    },
    closeModalStatus() {
      this.$refs.modalStatus.hide();
      this.$emit('update-kanban');
    },
    disableStatusButton(value) {
       this.disable_status_button = value;
    },
  },
};
</script>


<style></style>

<template>
  <div>
    <div v-if="policyRenewSelected && policyRenewSelected.policy_origin" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-sm-4">
                <label class="dbo-ui">Apólice de origem</label>
                <p class="cursor-pointer"
                  @click="redirect('details-policy', { policyId: policyRenewSelected.policy_origin.id })">
                  <i class="mdi mdi-folder-open fa fa-fw mr-1"></i>Apólice nº {{
                    policyRenewSelected.policy_origin.number}}
                </p>
              </div>
              <div class="col-sm-4">
                <label class="dbo-ui">Nova Cotação</label>
                <p class="cursor-pointer"
                  @click="redirect('details-quotation', { quotationId: policyRenewSelected.quotation.id })"
                  v-if="policyRenewSelected.quotation &&  policyRenewSelected.quotation.number">
                  <i class="mdi mdi-buffer fa fa-fw mr-1"></i>Cotação nº {{ policyRenewSelected.quotation &&  policyRenewSelected.quotation.number ? policyRenewSelected.quotation.number : '-' }}
                </p>
              </div>
              <div class="col-sm-4">
                <label class="dbo-ui">Usuário</label>
                <p>{{ policyRenewSelected.user ? policyRenewSelected.user.name : '---' }}</p>
              </div>
              <div class="col-12" v-if="policyRenewSelected.policy">
                <label class="dbo-ui">Nova Apólice</label>
                <p class="cursor-pointer"
                  @click="redirect('details-policy', { policyId: policyRenewSelected.policy.id })">
                  <i class="mdi mdi-folder-open fa fa-fw mr-1"></i>Apólice nº {{ policyRenewSelected.policy.number }}
                </p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Tomador</label>
                <p>{{ formatCnpj(policyRenewSelected.policy_origin.policy_holder_document) }} - {{
                  policyRenewSelected.policy_origin && policyRenewSelected.policy_origin.policy_holder_name }}</p>
              </div>
              <div class="col-12">
                <label class="dbo-ui">Segurado/Beneficiário</label>
                <p>{{ policyRenewSelected.insured_name ?? '--' }}</p>
              </div>


              <div class="col-12 mb-2">
                <label class="dbo-ui">Descrição</label>
                <textarea v-if="editing" cols="30" rows="3" class="form-control"
                  v-model="policyRenewSelected.description"></textarea>
                <p v-else>{{ policyRenewSelected.description ?? '---' }}</p>
              </div>

              <div class="col-12 mb-2">
                <label class="dbo-ui">Documentos anexados</label>
                <table v-if="policyRenewSelected.policy_renovation_files" class="pretty admin mb-3 shadow">
                  <thead>
                    <tr>
                      <th>Nome do documento</th>
                      <th>Criado em</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, key) in policyRenewSelected.policy_renovation_files" :key="file.id">
                      <td>{{ file.name }}</td>
                      <td>{{ formatDateTimeFromISO(file.created_at) }}</td>
                      <td class="auto-width">
                        <div class="btn-group">
                          <button @click="downloadFile(file)" type="button" v-b-tooltip.hover
                            title="Fazer download do arquivo" class="button btn btn-outline-primary btn-sm">
                            <i class="fa fa-download"></i>
                          </button>
                          <button @click="deleteFile(file, key)" type="button" v-b-tooltip.hover title="Remover arquivo"
                            :disabled="loading_delete" class="button btn btn-outline-primary btn-sm">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p v-else>---</p>
              </div>

              <div class="col-12" v-if="editing">
                <p>
                  <upload-data ref="upload" :files="files" :url="urlUpload" :title="'Envie os documentos necessários'"
                    :forceRedirect="true" @upload-finished="closeModalStatus()" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="status_id" :disabled="!editing" @change="disableStatusButton">
          <option value="null" disabled selected>Selecione...</option>
          <option v-for="(status, index) in options" :key="index" :value="status.value">
            {{ status.text }}
          </option>
        </select>
      </div>

      <div class="col-12 col-lg-12 pb-4" v-if="isMaster() && policyRenewSelected.status_id == 9">
        <label class="dbo-ui" for="">Motivo do Cancelamento</label>
        <select class="custom-select" v-model="cancellation_reason_id" :disabled="!editing" @change="disableStatusButton">
          <option value="null" disabled selected>Selecione...</option>
          <option v-for="(status, index) in reasonCancel" :key="index" :value="status.value">
            {{ status.text }}
          </option>
        </select>
      </div>

  </div>
</template>

<script>
import { UploadData } from '@/components';
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import policyRenovationService from "@/services/policy-renovation";
import authenticationService from "@/services/authentication";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import translateReasonCancelTypes from "@/enums/translate/reasonCancelTypes";

export default {
  name: "ModalStatusRenewal",
  mixins: [dateFormatter, commomsHelper],
  components: {
    UploadData
  },
  props: {
    policyRenewSelected: {
      type: Object,
    },
    editing: {
      type: Boolean,
    },
    urlUpload: {
      type: String,
    },
    files: {
      type: Array,
    }

  },
  data: () => ({
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateReasonCancelTypes: translateReasonCancelTypes,
    loading_delete: false,
    options: [],
    reasonCancel: [],
    status_id: null,
    cancellation_reason_id: null,
  }),
  created() {
    this.getStatusKanban();
    this.getReasonsCancel();
    this.status_id = this.policyRenewSelected.status_id;
    this.cancellation_reason_id = this.policyRenewSelected.cancellation_reason_id;
    this.disableStatusButton();
  },
  watch: {
    status_id: function (val) {
      this.policyRenewSelected.status_id = val;
    },
    cancellation_reason_id: function (val) {
      this.policyRenewSelected.cancellation_reason_id = val;
    }
  },
  methods: {
    isMaster() {
      return authenticationService.isMaster();
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    getStatusKanban() {
      this.listStatusKanbanPolicyRenewal()
        .forEach((element) => {
          this.options.push({ value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element) })
        })
    },
    getReasonsCancel() {
      this.listReasonCancel()
        .forEach((element) => {
          this.reasonCancel.push({ value: element, text: this.getEnumKeyByEnumValue(this.translateReasonCancelTypes, element) })
        })
    },
    downloadFile(file) {
      policyRenovationService
        .getPolicyRenovationFile(this.policyRenewSelected.id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data.url);
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    deleteFile(file, key) {
      this.loading_delete = true;
      policyRenovationService
        .destroyPolicyRenovationFile(file.policy_renovation_id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.policyRenewSelected.policy_renovation_files.splice(key, 1);
          this.loading_delete = false;
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    closeModalStatus() {
      this.$emit('close-modal-status');
    },
    uploadFiles() {
      this.$nextTick(() => {
        if (this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
          this.$refs.upload.$refs.dropzone.processQueue();
            return;
        }

      this.closeModalStatus();
      });
    },
    disableStatusButton() {
      let value =  this.status_id === null || (this.status_id == 9 && this.cancellation_reason_id == null);
      this.$emit('disable-status-button', value);
    }
  },
};
</script>

<style></style>

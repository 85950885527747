<template>
  <div class="list-view">
    <div class="gray-table">
      <table class="custom-table">
        <thead>
          <tr>
            <th>NRO. AP.</th>
            <th>TOMADOR</th>
            <th>SEGURADO</th>
            <th>PRÊMIO</th>
            <th>VENCIMENTO</th>
            <th class="text-center">STATUS</th>
            <th class="expires-in">VENCE EM</th>
            <th class="text-center">AÇÃO</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="policy in paginatedPolicies" :key="policy.number">
            <td>{{ policy.number ?? policy.policy_origin.number }}</td>
            <td>{{ truncateText(policy.policy_holder_name ?? '--') }}</td>
            <td>{{ truncateText(policy.policy_origin ? policy.policy_origin.insured_name : policy.insured_name) }}</td>
            <td>{{ formatCurrency(policy.policy_origin ? policy.policy_origin.insurance_premium :
              policy.insurance_premium) }}</td>
            <td>{{ formatDateLocal(policy.validity_expiration ?? policy.policy_origin.validity_expiration) }}</td>
            <td>
              <span :class="`status-pill ${getStatusClass(policy.column)}`"
                :style="{ backgroundColor: `var(--status-color)` }">
                {{ translateStatus(policy.column) }}
              </span>
            </td>
            <td>
              <span :class="getClassTextColor(policy.days_until_expiration)" class="pill-label-list">
                <small v-if="policy.days_until_expiration != '--'">{{ policy.days_until_expiration > 0 ? policy.days_until_expiration : 'há ' +
                 Math.abs(policy.days_until_expiration)}} DIAS</small>
                 <small v-else>{{policy.days_until_expiration}}</small> 
              </span>
            </td>
            <td class="text-center">
              <button v-if="policy.column == 1 || policy.column == 2" @click="showModalCreate(policy)"
                class="btn-action">
                <i class="fa fa-sync"></i>
              </button>
              <button
                @click="$can('policy-show') ? redirect('details-policy', { policyId: (policy.policy_origin ? policy.policy_origin.id : policy.id) }) : null"
                class="btn-action">
                <i class="fas fa-eye"></i>
              </button>
              <b-dropdown variant="ellipsis" size="sm" menu-class="" left no-caret>
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item v-if="policy.column != 1 && policy.column != 2"
                  @click="showModalStatus(policy, policy.column)">
                  <i class="fa fa-pencil-alt"></i> Alterar renovação da apólice
                </b-dropdown-item>
                <b-dropdown-item v-if="policy.column != 1 && policy.column != 2" @click="downloadAllFile(policy)">
                  <i class="fa fa-file-alt"></i> Fazer download de todos os documentos
                </b-dropdown-item>
                <b-dropdown-item v-if="policy.column == 1 || policy.column == 2" @click="automaticRenewal(policy)">
                  <i class="fa fa-clock font-16 me-1"></i> Renovação automatica
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <b-pagination v-model="currentPage" :total-rows="totalPolicies" :per-page="perPage" aria-controls="my-table"
          class="my-3 mb-4"></b-pagination>
      </div>
    </div>
    <div class="modal">
      <b-modal id="show-modal-create" ref="modalCreate" size="md" class="mt-4" hide-footer centered>
        <template #modal-title>Renovar apólice</template>
        <ManualPolicyRenewal :policy-renew-selected="policyRenewSelected" />
      </b-modal>
    </div>
    <div class="modal">
      <b-modal id="show-modal-automatic-renewal" ref="modalAutomaticRenewal" size="lg" hide-footer>
        <template #modal-title>Renovar apólice</template>
        <div class="row form">
          <div class="col-12">
            <AutomaticPolicyRenewal :policy-id="policyIdSelected" />
          </div>
        </div>
      </b-modal>
    </div>
    <b-modal id="modal-renovation-status" ref="modalStatus" size="lg">
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Alterar Renovação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da renovação</p>
          </div>
        </div>
      </template>
      <ModalStatusRenewal 
        :policy-renew-selected="policyRenewSelected" 
        :editing="editing" :url-upload="urlUpload"
        :files="files" 
        @close-modal-status="closeModalStatus" 
        @disable-status-button="disableStatusButton"
        ref="modalStatusRenewal" />
      <template #modal-footer>
        <button 
          type="button" class="btn btn-primary"
          @click="editing ? changeStatusPolicyRenovation() : $refs.modalStatus.hide();"
          :disabled="disable_status_button">
          <b-spinner v-if="loading" small></b-spinner> {{ editing ? 'Alterar' : 'Fechar' }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from '@/mixins/numericFormatter';
import authenticationService from "@/services/authentication";
import policyRenovationService from "@/services/policy-renovation";
import fileDownload from "js-file-download";
import commomsHelper from "@/mixins/commomsHelper";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import ManualPolicyRenewal from '@/components/Policy/Renewal/ManualPolicyRenewal.vue';
import ModalStatusRenewal from '@/components/Policy/Renewal/ModalStatusRenewal.vue';
import AutomaticPolicyRenewal from '@/components/Policy/Renewal/AutomaticPolicyRenewal.vue';


export default {
  name: "ListPolicyRenewal",
  mixins: [numericFormatterMixin, dateFormatter, commomsHelper],
  props: {
    itemsPolicies: {},
    itemsPolicyRenovation: {}
  },
  components: { ManualPolicyRenewal, ModalStatusRenewal, AutomaticPolicyRenewal },
  data() {
    return {
      translateStatusQuotationTypes: translateStatusQuotationTypes,
      statusQuotationTypes: statusQuotationTypes,
      currentPage: 1,
      perPage: 10,
      policyRenewSelected: {},
      loading_create: false,
      editing: false,
      options: [],
      loading: false,
      files: [],
      urlUpload: '',
      policyIdSelected: null,
      disable_status_button: true,
    };
  },
  created() {
    this.$emit('update-kanban')
  },
  computed: {
    allPolicies() {
      const policies = [];
      const columns = [1, 2, 15, 18, 16, 9];

      columns.forEach(column => {
        if (this.itemsPolicies[column]) {
          this.itemsPolicies[column].forEach(policy => {
            policy.column = column;
            policies.push(policy);
          });
        }
        if (this.itemsPolicyRenovation[column] && this.itemsPolicyRenovation[column]) {
          this.itemsPolicyRenovation[column].forEach(policy => {
            policy.column = column;
            policies.push(policy);
          });
        }
      });

      return policies.map(policy => {
        policy.days_until_expiration = '--';
        if (policy?.validity_expiration || policy?.policy_origin?.validity_expiration) {
          var validity_expiration = policy.validity_expiration ?? policy.policy_origin.validity_expiration;
          policy.days_until_expiration = this.calculateDaysUntilExpiration(validity_expiration) ;
        }
          return policy;
      });
    },
    totalPolicies() {
      return this.allPolicies.length;
    },
    paginatedPolicies() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.allPolicies.slice(start, start + this.perPage);
    }
  },
  methods: {
    calculateDaysUntilExpiration(expirationDate) {
      const today = new Date();
      const expiration = new Date(expirationDate);
      const timeDifference = expiration.getTime() - today.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return daysDifference;
    },
    truncateText(text, maxChar = 28) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    formatDateLocal(date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
    translateStatus(column) {
      const statusMap = {
        1: 'Renovação próxima',
        2: 'Aguardando contato',
        15: 'Aguardando doc',
        18: 'Aguardando aprovação',
        16: 'Finalizada',
        9: 'Cancelada'
      };
      return statusMap[column] || 'Desconhecido';
    },
    getStatusClass(column) {
      const statusClassMap = {
        1: 'kanban-list-status-orange',
        2: 'kanban-list-status-orange-66',
        15: 'kanban-list-status-orange-33',
        18: 'kanban-list-status-yellow',
        16: 'kanban-list-status-green',
        9: 'kanban-list-status-red'
      };
      return statusClassMap[column] || 'kanban-list-status-orange';
    },
    showModalCreate(policy) {
      if (policy) {
        this.policyRenewSelected = policy;
        this.$refs.modalCreate.show();
      }
    },
    showModalStatus(policy, column) {
      this.editing = column == 15 || column == 18;
      if (policy) {
        this.urlUpload = `/api/v1/policy-renovations/${policy.id}/files`;
        this.policyRenewSelected = policy;
        this.$refs.modalStatus.show();
      }
    },
    getClassTextColor(days) {
      if (days < 7) {
        return 'pill-label-danger';
      } else if (days < 15) {
        return 'pill-label-warning';
      }
      return 'pill-label-sucess';
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    automaticRenewal(policy) {
      this.policyIdSelected = policy.id;
      this.$refs.modalAutomaticRenewal.show();
    },
    createRenovation(policy) {
      this.$emit('create-renovation', policy)
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    closeModalStatus() {
      this.$refs.modalStatus.hide();
      this.$emit('update-kanban');
    },
    changeStatusPolicyRenovation() {
      this.loading = true;
      this.resetAlert();
      policyRenovationService
        .updateStatusPolicyRenovation(this.policyRenewSelected.id, this.policyRenewSelected)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.$refs.modalStatusRenewal.uploadFiles();

        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível mudar o status da renovação.');
        })
        .finally(() => {
          this.policyRenewSelected.status_id = null;
          this.loading = false;
        });
    },
    showModalDestroy() {
      if (this.policyRenewSelected) {
        this.deletion_msg =
          'Deseja realmente excluir a renovação de ' + '<strong>' + this.policyRenewSelected.policy_holder_name ?? '--' + '</strong>?';
        this.$refs.showModal.show();
      }
    },
    destroyPolicyRenovation() {
      this.loading_delete = true;
      this.$refs.showModal.hide();
      policyRenovationService
        .destroyPolicyRenovation(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.$emit('update-kanban');
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível remover a cotação.');
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading_delete = false;
        });
    },
    downloadFile(file) {
      policyRenovationService
        .getPolicyRenovationFile(this.policyRenewSelected.id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.openLinkTargetBlank(response.data.url);
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível realizar o download deste arquivo.');
        });
    },
    downloadAllFile(policyRenovation) {
      this.loading_download = true;
      policyRenovationService
        .getPolicyRenovationAllFiles(policyRenovation.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          fileDownload(response.data, 'download.zip');
          this.loading_download = false;
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível realizar o download deste arquivo.');
        });
    },
    deleteFile(file, key) {
      this.loading_delete = true;
      policyRenovationService
        .destroyPolicyRenovationFile(file.policy_renovation_id, file.id)
        .then((response) => {
          if (response.data.error) {
            this.$emit('show-error', response.data.message);
            return;
          }
          this.policyRenewSelected.policy_renovation_files.splice(key, 1);
          this.loading_delete = false;
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível realizar o download deste arquivo.');
        });
    },
    getStatusKanban() {
      this.listStatusKanbanPolicyRenewal()
        .forEach((element) => {
          this.options.push({ value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element) })
        })
    },
    disableStatusButton(value) {
       this.disable_status_button = value;
    },
  }
};
</script>

<style scoped></style>

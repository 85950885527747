<template>
  <div class="list-view">

    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div>
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="d-flex align-items-center">
          <strong>{{ msgLoading }}</strong>
        </div>
      </div>
    </div>

    <div class="gray-table table-responsive-billet" style="margin-bottom: 50px">
      <table class="custom-table no-wrap">
        <thead>
          <tr>
            <th>SEGURADORA</th>
            <th>Nº APOLICE</th>
            <th>TOMADOR/SEGURADO</th>
            <th>CNPJ TOMADOR</th>
            <th>MODALIDADE</th>
            <th>VALORES</th>
            <th>VIGÊNCIA</th>
            <th>DATAS</th>
            <th class="text-center">STATUS</th>
            <th class="text-center">AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index" role="row" style="--small-badge-width: 5ch;">
            <td data-column-title="Seguradora">
              <img :src="item.icon_insurer" alt="Logo da seguradora" class="insurance-logo" />
            </td>
            <td data-column-title="Nro. da Apólice">{{ item.number }}</td>
            <td data-column-title="Tomador/Segurado" style="--small-badge-width: 3ch;">
              <div class="d-flex flex-column">
                <span class="d-flex align-items-center text-truncate" style="max-width: 150px;"
                  :title="item.policy_holder_name">
                  <span class="badge badge-secondary mr-1" data-toggle="tooltip" title="Tomador"
                    style="width: var(--small-badge-width);">T</span>
                  <span class="truncate">{{ item.policy_holder_name }}</span>
                </span>
                <span class="d-flex align-items-center text-truncate" style="max-width: 150px;"
                  :title="item.insured_name">
                  <span class="badge badge-secondary mr-1" data-toggle="tooltip" title="Segurado"
                    style="width: var(--small-badge-width);">S</span>
                  <span class="truncate">{{ item.insured_name }}</span>
                </span>
              </div>
            </td>

            <td data-column-title="CNPJ do Tomador">
              {{ item.policy_holder_document }}
            </td>
            <td data-column-title="Modalidade">
              <span class="truncate-100 truncate-xxl-150 truncate-xxxl-300">
                {{ item.insurance_type_id }}
              </span>
            </td>
            <td data-column-title="Valores">
              <div class="d-flex flex-column">
                <span class="nowrap">
                  <span class="badge badge-secondary" data-toggle="tooltip" title="Importância Segurada"
                    style="width: var(--small-badge-width);">I.S</span>
                  {{ formatCurrency(item.coverage_value) }}
                </span>
                <span class="nowrap">
                  <span class="badge badge-secondary" data-toggle="tooltip" title="Prêmio"
                    style="width: var(--small-badge-width);">PR</span>
                  {{ formatCurrency(item.insurance_premium) }}
                </span>
              </div>
            </td>
            <td data-column-title="Vigência">
              <div class="d-flex flex-column">
                <span class="nowrap">
                  <span class="badge badge-secondary" data-toggle="tooltip" title="Início da Vigência"
                    style="width: var(--small-badge-width);">DE</span>
                  {{ item.validity_start }}
                </span>
                <span class="nowrap">
                  <span class="badge badge-secondary" data-toggle="tooltip" title="Término da Vigência"
                    style="width: var(--small-badge-width);">ATÉ</span>
                  {{ item.validity_expiration }}
                </span>
              </div>
            </td>
            <td data-column-title="Vencimento" style="--small-badge-width: 4ch;">
              <div class="d-flex flex-column">
                <span class="d-flex align-items-center" style="min-width: 100px;">
                  <span class="badge badge-secondary no-wrap mr-1" data-toggle="tooltip" title="Vencimento"
                    style="white-space: nowrap;">
                    V
                  </span>
                  {{ item.end_date }}
                </span>
                <span v-if="item.extended_date != '-'" class="d-flex align-items-center" style="min-width: 100px;">
                  <span class="badge badge-secondary mr-1" data-toggle="tooltip" title="Prorrogado para">
                    P
                  </span>
                  <span :class="{ 'ml-4': item.extended_date === '-' }" style="flex: 1;">
                    {{ item.extended_date }}
                  </span>
                </span>
              </div>
            </td>

            <td data-column-title="Status">
              <span
                :class="['btn btn-sm status-badge text-uppercase rounded-pill nowrap d-block status-billet']"
                :style="{ '--status-billet': getStatusBadgeColor(checkStatus(item)) }"
                style="min-width: 120px; margin: 0;">
                {{ checkStatus(item) }}
              </span>

            </td>
            <td data-column-title="Ação" class="actions text-right">
              <div class="btn-group">
                <button class="btn btn-action" @click="openModalProrrogar(item)">
                  <i class="far fa-calendar-plus" data-toggle="tooltip" title="Prorrogar o boleto"></i>
                </button>

                <button class="btn btn-action">
                  <i class="fa fa-paperclip" data-toggle="tooltip" title="Fazer download do boleto"
                    @click="getBillet(item)"></i>
                </button>
                <button v-if="item.status !== 'PAGO'"
                  class="fa fa-toggle-on fa-flip-horizontal fa-2x text-muted border-0 bg-transparent"
                  data-toggle="tooltip" title="Marcar boleto como pago" @click="markBilletAsPaid(item)"></button>

                  <button v-else class="fa fa-toggle-on fa-2x text-primary border-0 bg-transparent"></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-container">
        <b-pagination @change="handlePageChange" v-model="currentPage" :total-rows="totalItems" :per-page="perPage"
          class="my-3 mb-4"></b-pagination>
      </div>
    </div>

    <b-modal ref="modalProrrogar" id="modal-prorrogar" size="lg" class="mt-4" centered>
      <template #modal-title>
        Prorrogar Boleto
      </template>

      <form style="padding-inline: 1.5rem">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="form-subheader"><span>Dados Gerais</span></div>
          </div>
          <div class="col-12 col-lg-3 mb-3">
            <img :src="selectedBillet?.icon_insurer" class="modal-insurance-logo" style="max-height: 60px;">
          </div>
          <div class="col-12 col-lg-3 mb-3">
            <label class="dbo-ui">Nro. Apólice</label>
            <p>{{ selectedBillet?.number }}</p>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui">Modalidade de Seguro</label>
            <p>{{ selectedBillet?.insurance_type_id }}</p>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui">Tomador</label>
            <p>{{ selectedBillet?.policy_holder_name }}</p>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label class="dbo-ui">Segurado</label>
            <p>{{ selectedBillet?.insured_name }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-3">
            <div class="form-subheader"><span>Valores</span></div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="dbo-ui">Importância Segurada</label>
            <p>{{ formatCurrency(selectedBillet?.coverage_value) }}</p>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="dbo-ui">Prêmio</label>
            <p>{{ formatCurrency(selectedBillet?.insurance_premium) }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="dbo-ui">Data de vencimento atual</label>
            <p class="form-control-plaintext"><strong>{{ selectedBillet?.extended_date && selectedBillet?.extended_date
              != '-'
              ?
              selectedBillet?.extended_date :
              selectedBillet?.end_date }}</strong></p>
          </div>
          <div class="col-12 col-lg-6">
            <label class="dbo-ui">Digite a nova data de vencimento do boleto</label>
            <input v-model="newDate" type="date" required="required" list="policyHolderList"
              class="mb-3 form-control form-control-lg">
          </div>
        </div>
      </form>

      <template #modal-footer>
        <b-button @click="changeDate(selectedBillet?.id)" variant="primary">Prorrogar</b-button>
      </template>
    </b-modal>


  </div>
</template>

<script>
import billetService from "@/services/billet";
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from "@/mixins/numericFormatter";

export default {
  name: "DatatableBoletos",
  mixins: [dateFormatter, numericFormatterMixin],
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      newDate: null,
      perPage: 10,
      totalItems: 0,
      lastPage: 1,
      msgLoading: "Carregando boletos...",
      loading: false,
      selectedBillet: null,
    };
  },
  watch: {
    currentPage() {
      this.fetchBillets(this.filters);
    },
  },
  created() {
    this.fetchBillets();
  },
  methods: {
    markBilletAsPaid(item) {
      this.loading = true;

      billetService.markAsPaid(item.id)
        .then(() => {
          this.fetchBillets();
          this.showSuccess("Boleto marcado como pago com sucesso.");
        })
        .catch(() => {
          this.showError("Não foi possível marcar o boleto como pago.");
        })
    },
    getBillet(item) {
      this.loading = true;

      billetService.getBillet(item.id)
        .then((response) => {
          this.openLinkTargetBlank(response.data.quotation.policy.billing_file);
        })
        .catch(() => {
          this.showError("Não foi possível marcar o boleto como pago.");
        }).finally(() => {
          this.loading = false;
        });
    },
    getStatusBadgeClass(status) {
      switch (status) {
        case 'PAGO':
          return 'status-badge-billet-green';
        case 'EM ABERTO':
          return 'status-badge-billet-yellow';
        case 'VENCIDO':
          return 'status-badge-billet-red';
        default:
          return 'status-badge-billet-default';
      }
    },
    getStatusBadgeColor(status) {
      switch (status) {
        case 'PAGO':
          return '#20c997';
        case 'EM ABERTO':
          return '#ffb635';
        case 'VENCIDO':
          return 'crimson';
        default:
          return '#6c757d';
      }
    },
    fetchBillets(filters = {}) {
      this.loading = true;
      billetService.get({ page: this.currentPage, per_page: this.perPage, ...filters }).then(response => {
        this.formatList(response.data.data);
        this.totalItems = response.data.total;
        this.lastPage = response.data.last_page;
      }).catch(() => {
        this.showError("Não foi possível recuperar os boletos.");
      }).finally(() => {
        this.loading = false;
      });
    },
    applyFiltersToBillets(filters) {
      this.fetchBillets(filters);
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    checkStatus(item) {
      if (item.status === 'PAGO') return item.status;

      const today = this.parseDate(new Date().toLocaleDateString('pt-BR'));

      const dateToCheck = item.extended_date !== '-'
        ? this.parseDate(item.extended_date)
        : this.parseDate(item.end_date);

      return dateToCheck < today ? 'VENCIDO' : item.status;
    },

    parseDate(dateStr) {
      const [day, month, year] = dateStr.split('/');
      return new Date(`${year}-${month}-${day}`);
    },
    formatList(data) {
      const mappedItems = data.map((element) => ({
        id: element.id,
        number: element?.quotation?.policy?.number,
        policy_holder_name: element?.quotation?.policy?.policy_holder_name,
        insured_name: element?.quotation?.policy?.insured_name,
        policy_holder_document: element?.quotation?.policy?.policy_holder_document,
        insurance_type_id: element?.quotation?.insurance_category?.name,
        coverage_value: element?.quotation?.policy?.coverage_value,
        insurance_premium: element?.quotation?.policy?.insurance_premium,
        validity_start: this.formatDateLocal(element?.quotation?.policy?.validity_start),
        validity_expiration: this.formatDateLocal(element?.quotation?.policy?.validity_expiration),
        extended_date: element.extended_date ? this.formatDateLocal(element.extended_date) : '-',
        end_date: this.formatDateLocal(element.end_date),
        status: element.status?.translate,
        icon_insurer: element.quotation?.policy?.insurer?.icon,
        billet_url: element.quotation?.policy?.billing_file,
      }));

      this.items = mappedItems;

    },
    changeDate(id) {
      this.loading = true;
      billetService
        .changeDate(id, { date: this.newDate })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.$refs.modalProrrogar.hide();
          this.fetchBillets();
          this.showSuccess('Boleto prorrogado com sucesso.');
        })
        .catch(() => {
          this.showError("Não foi possível alterar data do boleto.");
        })
    },
    getEnumKeyByEnumValue(enumObject, value) {
      return Object.keys(enumObject).find(key => enumObject[key] === value);
    },
    openModalProrrogar(item) {
      this.newDate = null;
      this.selectedBillet = item;
      this.$refs.modalProrrogar.show();
    },
    openLinkTargetBlank(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style>
</style>
